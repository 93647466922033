import React from 'react'
import { graphql, Link } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// import ShareSocials from '../components/shareSocials'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Head from '../components/head'

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      publishedDate(formatString: "DD MMMM YYYY", locale: "fr")
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(width: 900)
          }
        }
      }
      image {
        title
        gatsbyImageData
      }
      images {
        title
        gatsbyImageData
      }
    }
  }
`

const Blog = ({ data }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const image = getImage(node.data.target)
        return (
          <GatsbyImage
            alt={node.data.target.title}
            image={image}
            objectFit="cover"
            className="post__richtext-image"
          />
        )
      },
    },
  }

  const mainImage = getImage(data.contentfulBlogPost.image)

  return (
    <Layout>
      <Head
        title={data.contentfulBlogPost.title}
        image={mainImage}
        description={`${data.contentfulBlogPost.title} | par Jérôme Dicharry développeur web`}
      />
      <section className="section section--small">
        <h2>{data.contentfulBlogPost.title}</h2>
        <p>{data.contentfulBlogPost.publishedDate}</p>
        <GatsbyImage
          className="post-image"
          image={mainImage}
          alt={data.contentfulBlogPost.image.title}
        />
      </section>
      <section className="section section--small post--content">
        {renderRichText(data.contentfulBlogPost.content, options)}
        {data.contentfulBlogPost.images &&
          data.contentfulBlogPost.images.map((image) => {
            const currentImage = getImage(image)
            return (
              <div className="mw-800">
                <GatsbyImage
                  image={currentImage}
                  alt={image.title}
                  className="mt-4"
                />
              </div>
            )
          })}
      </section>
      <section className="section section--small">
        {/* <ShareSocials
          title={data.contentfulBlogPost.title}
          url={`https://developpeur-web-strasbourg.fr/articles/${data.contentfulBlogPost.slug}`}
        /> */}
        <Link to="/blog" className="button primary">
          Retour aux articles
        </Link>
      </section>
    </Layout>
  )
}

export default Blog
